import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import Swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router,private spinner: NgxSpinnerService,private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            this.spinner.hide()
            // 🤯
            switch (err.status) {
                case 401:
                    Swal.fire(
                        'خطأ',
                        err.error.message,
                        'error'
                      )
                    // Toast().fire({ icon: 'error', title:  err.error.message })
                    // this.router.navigate(['/login'])
                    this.authenticationService.logout()
                    break;
                case 400:
                    
                    if(err.error.message){
                        Swal.fire(
                            'خطأ',
                            `${err.error.message}`,
                            'error'
                          )
                    }
                    if(err.error.msg){
                        Swal.fire(
                            'خطأ',
                            `${err.error.msg}`,
                            'error'
                          )
                        // Toast().fire({ icon: 'error', title: err.error.messages })
// return 0
                    }else{
                        for (const [key, error] of Object.entries(err.error.messages)) {
                            Swal.fire(
                                'خطأ',
                                `${error}`,
                                'error'
                              )
                            // if(error != "The token field is required.")
                                // Toast().fire({ icon: 'error', title: error })
                        }
                    }
                    // console.log(err)
                    // console.log(err.error.msg)
                  
                    break;
                case 403:
                    Swal.fire(
                        'خطأ',
                        `عفواً لقد انتهت الجلسة سيتم تسجيل الخروج تلقائياً`,
                        'error'
                      )
                    this.authenticationService.logout();  
                    break;

                    case 409:
                        err.error.data.forEach(error => {
                            Swal.fire(
                                'خطأ',
                                `${error.msg}`,
                                'error'
                              )
                        });
                        break;

                 
                case 500:
                    Swal.fire(
                        'خطأ',
                        `حدث خطأ في الخادم . حاول لاحقًا`,
                        'error'
                      )
                    // Toast().fire({ icon: 'error', title: 'حدث خطأ في الخادم . حاول لاحقًا' })
                    break;
                default:
                    for (const [key, error] of Object.entries(err.error.message)) {
                        Swal.fire(
                            'خطأ',
                            `${error}`,
                            'error'
                          )
                        // Toast().fire({ icon: 'error', title: error })
                    }
                    break;
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}