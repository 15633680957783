import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminModule } from './components/admin/admin.module';
import { LoginComponent } from './components/auth/login/login.component';
import { ComplaintsComponent } from './components/complaints/complaints.component';
import { DepartmentDetailsComponent } from './components/department-details/department-details.component';
import { DepartmentComponent } from './components/department/department.component';
import { HomeComponent } from './components/home/home.component';
import { PakedgesModule } from './components/pakedges/pakedges.module';
import { SettingsModule } from './components/settings/settings.module';
import { UsersModule } from './components/users/users.module';
import { AuthGuard } from './guards/auth.guard';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';


const routes: Routes = [
   {path:'',component:LoginComponent},
   {path:'login',component:LoginComponent},

  { 
    path: 'app',
    component: AppLayoutComponent, 
    canActivate: [AuthGuard],
    children: [
      {path:'',component:HomeComponent},
      {path:'home',component:HomeComponent},
      {path:'complaints',component:ComplaintsComponent},
      {path: "admin", loadChildren: "./components/admin/admin.module#AdminModule"},
      {path: "users", loadChildren: "./components/users/users.module#UsersModule"},
      {path: "settings", loadChildren: "./components/settings/settings.module#SettingsModule"},
      {path: "pakedges", loadChildren: "./components/pakedges/pakedges.module#PakedgesModule"},
      {path:'departments',component:DepartmentComponent},
      {path:'department/:id/:name',component:DepartmentDetailsComponent},
      

    ]
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
