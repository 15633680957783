import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public user

  constructor(private http:HttpClient,private authentication:AuthenticationService) { 
    this.authentication.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
  }
  
  getHomeData(){
    return this.http.get(`${environment.endPoint}/dashboard/home/info`)
  }
  // Settings
  getSettingsData(){
    return this.http.get(`${environment.endPoint}/getSettings`)
  }

  setPrivacy(form){
    return this.http.post(`${environment.endPoint}/dashboard/privacy/set`,form)
  }
  getPrivacy(){
    return this.http.get(`${environment.endPoint}/privacy`)
  }

  setSettings(form){
    console.log(form)
    return this.http.post(`${environment.endPoint}/setSettings`,form)
  }
  // Pakedges
  addPlan(form){
    console.log(form)
    return this.http.post(`${environment.endPoint}/package/create`,form)
  }
  getPakedges(){
    return this.http.get(`${environment.endPoint}/package/get/all`)
  }
  deletePakedge(pakedge_id){
    return this.http.post(`${environment.endPoint}/package/delete`,{id:pakedge_id})
  }


  // Complaints
  // https://backend.gathering.host/getComplaints
  getComplaints(){
    return this.http.get(`${environment.endPoint}/getComplaints`)
  }

  // admins
  getAdmins(){
    return this.http.get(`${environment.endPoint}/dashboard/user/admin/info`)
  }
  addAdmin(form){
    return this.http.post(`${environment.endPoint}/dashboard/admin/create`,form)
  }
  deleteAdmins(admin_id){
    return this.http.post(`${environment.endPoint}/dashboard/admin/delete`,{id:admin_id})
  }
  // teachers
  getTeachers(page){
    return this.http.get(`${environment.endPoint}/dashboard/user/teacher/info?page=${page}`)
  }
  addTeacher(form){
    return this.http.post(`${environment.endPoint}/teacher-signup`,form)
  }
  deleteTeacher(teacher_id){
    var form = new FormData()
    form.append('id',teacher_id)
    return this.http.post(`${environment.endPoint}/dashboard/user/account/delete`,form)
  }
  // get students list
  getStudents(page = 1){
    return this.http.get(`${environment.endPoint}/dashboard/user/student/info?page=${page}`)
  }
  addStudent(form){
    return this.http.post(`${environment.endPoint}/dashboard/user/account/create`,form)
  }
  deleteStudent(student_id){
    return this.http.post(`${environment.endPoint}/dashboard/user/account/delete`,{id:student_id})
  }

  getDepartments(page = 1){
    return this.http.get(`${environment.endPoint}/dashboard/department/get/all?page=${page}`)
  }

  getDepartment(id,page = 1){
    return this.http.get(`${environment.endPoint}/department/${id}/users/get/?page=${page}`)
  }
  allStudent(){
    return this.http.get(`${environment.endPoint}/dashboard/user/student/info`)
  }
// delete user from department
deleteuserdepartment(userId,departmentId){
  const json = {userId:userId,departmentId:departmentId}
    return this.http.post(`${environment.endPoint}/dashboard/department/delete/user`,json)
  }
  adduserdepartment(userId,departmentId){
    return this.http.post(`${environment.endPoint}/dashboard/department/add/user`,{email:userId,departmentId:departmentId})
  }
}
// http://localhost:8080/dashboard/department/user/
// https://www.gathering.host/dashboard/department/delete/user
