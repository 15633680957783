import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss']
})
export class ComplaintsComponent implements OnInit {
  complaints = []
  constructor(private service:AppService) { }

  ngOnInit(): void {
    this.service.getComplaints().subscribe((response:any)=>{
      this.complaints = response
      console.log(response)
      console.log(response)
    })
  }

}
