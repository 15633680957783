import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-department-details',
  templateUrl: './department-details.component.html',
  styleUrls: ['./department-details.component.scss']
})
export class DepartmentDetailsComponent implements OnInit {
  data
  students
  public id = this.route.snapshot.paramMap.get('id')
  public name = this.route.snapshot.paramMap.get('name')    
  baseURL = environment.baseURL

  constructor(private services:AppService,private spinner:NgxSpinnerService,public route: ActivatedRoute,private http:HttpClient) { }

  ngOnInit(): void {
    this.services.getDepartment(this.id).subscribe((reseponse:any)=>{
      this.data = reseponse.member
    })
    this.services.allStudent().subscribe((reseponse:any)=>{
      this.students = reseponse.data
    })
  }
  delete(id){
    if(confirm(`هل انت متأكد من الحذف ؟`)){

  //     const body = new HttpParams()
  //   .set('userId', id)
  //   .set('departmentId', this.id);

  // return this.http.post(`${environment.endPoint}/dashboard/department/user`,
  //   body.toString(),
  //   {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', 'application/x-www-form-urlencoded')
  //   }
  // );


      this.services.deleteuserdepartment(id,this.id).subscribe((reseponse:any)=>{
        console.log('==================####=================')
        console.log(reseponse)
        this.services.getDepartment(this.id).subscribe((reseponse:any)=>{
          this.data = reseponse.member
        })
      })
    }
  }
  // userId = null
  // email
  // select(e){
  //   this.userId = e
  //   console.log(e)
  // }
  @ViewChild("emailInput") private emailInputRef: ElementRef;
isValidEmail = false; 
ngAfterViewInit(): void {
  fromEvent(this.emailInputRef.nativeElement, 'keyup')
  .subscribe(() => {
      var re = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$");
      this.isValidEmail = re.test(this.emailInputRef.nativeElement.value);
  }); 
}

  submit(){
    console.log(this.emailInputRef.nativeElement.value)
    this.services.adduserdepartment(this.emailInputRef.nativeElement.value,this.id).subscribe((reseponse:any)=>{
      console.log('==================####=================')
      console.log(reseponse)
      alert('نجاح')
      this.services.getDepartment(this.id).subscribe((reseponse:any)=>{
        this.data = reseponse.member
      })
    })
  }
}
