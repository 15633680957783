import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
  toggler = false
  public user;
  public timeNow: Date = new Date();
  public welcomePhrase;
  userDropdown = false
  constructor(private auth:AuthenticationService) { 
    this.auth.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
  }

  ngOnInit(): void {
    this.getDate()
  }

  getDate(){
    setInterval(() => { this.timeNow = new Date() }, 1);
    var myDate = new Date();
    var hrs = myDate.getHours();
    if (hrs < 12)
      this.welcomePhrase = 'صباح الخير';
    else 
      this.welcomePhrase = 'مساء الخير';
  }
  logout(){
    this.auth.logout()
  }
}
