import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public showPassword = false;
  public submitted=false;
  public form:FormGroup

  constructor(private router: Router,private formbuilder:FormBuilder,private spinner:NgxSpinnerService,private auth:AuthenticationService) { 
    if (this.auth.currentUserValue) { this.router.navigate(["/app/home"]) }
  }

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      email:['',Validators.required],
      password:['',Validators.required]
    })
  }
  get f(){return this.form.controls}
  submit(){
    this.submitted=true;
    if(this.form.invalid){return}
    console.log(this.form.value)
    this.spinner.show()
    // this.auth.fakelogin()
    // Swal.fire(
    //   'نجاح ! 🤓',
    //   'تم تسجيل الدخول بنجاح',
    //   'success'
    // )
    this.router.navigate([`/app`])

    // 

      this.auth.login(this.form.value['email'],this.form.value['password']).subscribe((response:any)=>{
        console.log(response)
        console.log(response)
        if (response.status) {
          Swal.fire(
            'نجاح ! 🤓',
            'تم تسجيل الدخول بنجاح',
            'success'
          )
        this.router.navigate([`/app`])

        } else {
          Swal.fire(
            'خطأ! ☹️',
            'عذراً , البيانات المدخلة غير صحيحة',
            'error'
          )
        }
        this.spinner.hide()
      })
  }
  
}
