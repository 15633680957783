import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    public token: string;

    constructor(private router: Router,private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
  
    public get currentUserValue(): any {
        if(this.currentUserSubject.value != null) { return this.currentUserSubject.value }        
    }
    // sign in method
    login(email: string, password: string): Observable<any> {
        return this.http.post<any>(`${environment.endPoint}/auth/signin`, { email, password })
            .pipe(map(user => {
                if (user && user.token) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }
    fakelogin(){
      const res = {"status":true,"data":"admin-1","access_token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQGdtYWlsLmNvbSIsImlhdCI6MTYxMzQzMTczMywiZXhwIjoxNjQ1MDU0MTMzfQ.siTY4V7bCKkPtmaqxvTNwNbVePPj3er_KQ3l2btaJi4"};
      localStorage.setItem('currentUser', JSON.stringify(res));
      this.currentUserSubject.next(res);
    }
    // sign out method
    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/login'])
    }
}
