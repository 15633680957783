import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {
  departments :any
  baseURL = environment.baseURL
  load = true;

  constructor(private services:AppService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.getDepartments(1)
  }
  getDepartments(page){
    this.spinner.show()
    this.load = true
    this.services.getDepartments(page).subscribe((response:any)=>{
      console.log(response)
      this.departments = response
      this.spinner.hide()
      this.load = false
    })
  }
  // getDepartments
}
